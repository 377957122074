<template>
  <v-card flat>
    <v-list-item
      v-for="member in members"
      :key="member.uid"
    >
      <v-list-item-avatar>
        <v-img
          :src="member.photoURL | mImage"
        />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>
          {{ member.displayName }}
        </v-list-item-title>
        <v-list-item-subtitle>
          Last Sign In: {{ member.metadata.lastSignInTime | formatDate }}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action v-if="organization.createdBy === member.uid">
        <v-chip>Owner</v-chip>
      </v-list-item-action>
    </v-list-item>
    <!-- <validation-observer tag="form" v-slot="{ invalid }" @submit.prevent="inviteMember">
      <v-list-item>
        <v-list-item-avatar>
          <v-icon>
            mdi-plus
          </v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <validation-provider
            v-slot="{ errors }"
            rules="email"
            name="Email"
          >
            <v-text-field
              v-model="inviteMemberEmail"
              label="Email of user to invite"
              type="text"
              outlined
              :error-messages="errors"
              :hide-details="!errors.length"
              autocomplete
              autofocus
              required
            />
          </validation-provider>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn
            type="submit"
            :disabled="invalid"
            color="primary"
            outlined
            large
          >
            Send Invitation Link
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </validation-observer> -->
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Team',
  data: () => ({
    loading: true,
    members: [],
    inviteMemberEmail: ''
  }),
  computed: {
    ...mapGetters(['organization'])
  },
  async beforeMount() {
    this.members = await Promise.all(this.organization.memberUserIds.map(async(uid) => {
      const { data } = await this.$axios.get(`${this.$apiUrl}/auth/user/`, {
        params: { uid }
      })
      return data
    }))
    this.loading = false
  }
}
</script>
